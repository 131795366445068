* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  background-color: rgb(255, 253, 253);
}

.webindex {
  margin: 0 auto;
 height: 100vh;
 width: 100vw;
}

.loading{
  width: 450px;
  font-size: 40px;
  animation-name: load;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  margin: 0 auto;
  margin-left: -130px;
  margin-top: 10px;
  }


.loadingloop{

  margin-left: -20px;
  margin-top: -40px;
}

.ready{
  animation-name: button-chicken;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  opacity: 0;
  display: none;
  }

.guready{
  animation-name: button-chicken;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  opacity: 0;
  display: none;
  }  

@keyframes load {
0%   {opacity: 0;}
50%  {opacity: 1;}
100% {opacity: 0;}
}

@keyframes button-chicken {
  0%   {opacity: 1; display:block;}
  100% {opacity: 0;display: none;}
}